import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useAuthState } from '../context/auth';

const URL = process.env.REACT_APP_SOCKET_SERVER_URL;
const SocketContext = React.createContext();

export function useSocket() {
	return useContext(SocketContext);
}

export function SocketProvider({ children }) {
	const { loggedIn, validatedLegacyCredentials } = useAuthState();
	const [socket, setSocket] = useState();

	useEffect(() => {
		if (!loggedIn) return;

		const newSocket = io(URL, {
			transports: ['websocket'],
			withCredentials: true,
		});
		setSocket(newSocket);
		return () => newSocket.close();
	}, [loggedIn, validatedLegacyCredentials]);

	return (
		<SocketContext.Provider value={socket}>
			{children}
		</SocketContext.Provider>
	);
}
